import * as React from "react"
import { motion, useAnimation } from "framer-motion"

export function F07DynamicStartFM() {
    const controls = useAnimation()

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                perspective: 800,
                cursor: "pointer",
            }}
            onTap={async () => {
                await controls.start(custom => ({
                    rotateY: 180,
                    backgroundColor: custom,
                    transition: { duration: 1 },
                }))
                controls.start({
                    rotateY: 0,
                    backgroundColor: "#fff",
                    transition: { duration: 1 },
                })
            }}
        >
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 75,
                    top: 75,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={controls}
                custom="#19e"
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 75,
                    top: 75,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={controls}
                custom="#f74"
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 75,
                    bottom: 75,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={controls}
                custom="#fa2"
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 75,
                    bottom: 75,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={controls}
                custom="#6b6"
            />
        </motion.div>
    )
}
