import * as React from "react"
import { motion, useAnimation } from "framer-motion"

export function F01StartingAndStoppingFM() {
    const animation1 = useAnimation()
    const animation2 = useAnimation()

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <motion.div
                style={{
                    width: 120,
                    height: 120,
                    borderRadius: 25,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 60,
                    top: 60,
                }}
                animate={animation1}
                transition={{ yoyo: Infinity }}
                onHoverStart={() => animation1.start({ scale: 1.25 })}
                onHoverEnd={() => animation1.stop()}
            />
            <motion.div
                style={{
                    width: 120,
                    height: 120,
                    borderRadius: 25,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 60,
                    bottom: 60,
                }}
                animate={animation2}
                onHoverStart={() =>
                    animation2.start({
                        scale: 1.25,
                        transition: { yoyo: Infinity },
                    })
                }
                onHoverEnd={() => animation2.start({ scale: 1 })}
            />
        </div>
    )
}
