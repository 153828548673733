import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function E03MultiplePropertiesFM() {
    const [current, next] = useCycle(
        {
            borderColor: "#32CD32",
            boxShadow: "0px 0px 0px 3px #fff",
        },
        {
            borderColor: "#f00",
            boxShadow: "0px 0px 20px 3px #fff",
        }
    )
    const [text, nextText] = useCycle("Start", "....", "Stop", "....")

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 60,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 0px 3px #fff",
                    border: "5px solid #32CD32",
                    boxSizing: "border-box",
                    color: "#333",
                    fontSize: 32,
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                    cursor: "pointer",
                }}
                initial={{ borderColor: "#32CD32" }}
                animate={current}
                transition={{ delay: 1 }}
                onTap={() => {
                    next()
                    nextText()
                    setTimeout(() => {
                        nextText()
                    }, 1000)
                }}
            >
                {text}
            </motion.div>
        </Center>
    )
}
