import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"

export function D07UsingUseStateOnlyForStateFM() {
    const [dragging, setDragging] = useState(false)

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#80F",
                overflow: "hidden",
                display: "flex",
                placeItems: "center",
                placeContent: "center",
            }}
            animate={{ backgroundColor: dragging ? "#09F" : "#80F" }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: dragging ? "grabbing" : "grab",
                }}
                // Dragging
                drag
                dragConstraints={{
                    top: -140,
                    right: 140,
                    bottom: 140,
                    left: -140,
                }}
                // Animation
                animate={{
                    scale: dragging ? 1 : 0.8,
                }}
                onDragStart={() => {
                    setDragging(true)
                }}
                onDragEnd={() => {
                    setDragging(false)
                }}
            />
        </motion.div>
    )
}
