import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function B05SeparateTransitionValues1FM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    y: -90,
                }}
                animate={{ y: 70, rotate: 360 }}
                transition={{
                    delay: 1,
                    duration: 2,
                    ease: [0.075, 0.82, 0.165, 1],
                    yoyo: Infinity,
                    rotate: {
                        duration: 2,
                        ease: "linear",
                        loop: Infinity,
                    },
                }}
            />
        </Center>
    )
}
