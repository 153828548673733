import React from "react"
import { BrowserRouter as Router, useLocation } from "react-router-dom"

import { Refresh } from "./Refresh"

import { A02AnimatingPropertiesFM } from "./Framer Motion/A/A02 - Animating properties"
import { A03TransformPropertiesFM } from "./Framer Motion/A/A03 - Transform properties"
import { A04TransformOriginFM } from "./Framer Motion/A/A04 - Transform origin"
import { A05ColorsFM } from "./Framer Motion/A/A05 - Colors"
import { A06ComplexValuesFM } from "./Framer Motion/A/A06 - Complex values"
import { B01TheTransitionPropertyFM } from "./Framer Motion/B - transition/B01 - The Transition property"
import { B02SpringSettingsFM } from "./Framer Motion/B - transition/B02 - Spring settings"
import { B03LoopAfterDelayFM } from "./Framer Motion/B - transition/B03 - Loop after Delay"
import { B04YoyoWithBezierFM } from "./Framer Motion/B - transition/B04 - Yoyo with Bezier curve"
import { B05SeparateTransitionValues1FM } from "./Framer Motion/B - transition/B05 - Separate Transition values 1"
import { B06SeparateTransitionValues2FM } from "./Framer Motion/B - transition/B06 - Separate Transition values 2"
import { C01WhileHoverFM } from "./Framer Motion/C - whileHover & whileTap/C01 - WhileHover"
import { C02WhileTapFM } from "./Framer Motion/C - whileHover & whileTap/C02 - WhileTap"
import { C03WhileHoverWhileTapFM } from "./Framer Motion/C - whileHover & whileTap/C03 - WhileHover and WhileTap"
import { C04VariantsFM } from "./Framer Motion/C - whileHover & whileTap/C04 - Variants"
import { C05VariantsAndChildrenFM } from "./Framer Motion/C - whileHover & whileTap/C05 - Variants and Children"
import { C06OrchestrationFM } from "./Framer Motion/C - whileHover & whileTap/C06 - Orchestration"
import { D01TheUseStateHookFM } from "./Framer Motion/D - useState()/D01 - The useState() hook"
import { D02MoreStatesFM } from "./Framer Motion/D - useState()/D02 - More states"
import { D03UsingCurrentState1FM } from "./Framer Motion/D - useState()/D03 - Using the current state 1"
import { D04UsingCurrentState2FM } from "./Framer Motion/D - useState()/D04 - Using the current state 2"
import { D05MultiplePropertiesFM } from "./Framer Motion/D - useState()/D05 - Multiple properties"
import { D06SeparateUseStateForEachPropertyFM } from "./Framer Motion/D - useState()/D06 - Separate useState() for each property"
import { D07UsingUseStateOnlyForStateFM } from "./Framer Motion/D - useState()/D07 - Using useState() only for state CC"
import { E01TheUseCycleHookFM } from "./Framer Motion/E - useCycle()/E01 - The useCycle() hook"
import { E02AnimationFM } from "./Framer Motion/E - useCycle()/E02 - Animation"
import { E03MultiplePropertiesFM } from "./Framer Motion/E - useCycle()/E03 - Multiple properties"
import { E04CyclingToValueFM } from "./Framer Motion/E - useCycle()/E04 - Cycling to a specific value"
import { E05VariantsFM } from "./Framer Motion/E - useCycle()/E05 - Variants"
import { E06ParentChildVariantsFM } from "./Framer Motion/E - useCycle()/E06 - Parent and child variants"
import { F01StartingAndStoppingFM } from "./Framer Motion/F - useAnimation()/F01 - The useAnimation() hook"
import { F02ReturnAfterDragFM } from "./Framer Motion/F - useAnimation()/F02 - Return after dragging"
import { F03SequenceFM } from "./Framer Motion/F - useAnimation()/F03 - Sequence"
import { F04SequenceWithPausesFM } from "./Framer Motion/F - useAnimation()/F04 - Sequence with pauses"
import { F05MultipleFramesFM } from "./Framer Motion/F - useAnimation()/F05 - Multiple Frames"
import { F06SeparateFramesFM } from "./Framer Motion/F - useAnimation()/F06 - Separate Frames"
import { F07DynamicStartFM } from "./Framer Motion/F - useAnimation()/F07 - Dynamic start"
import { G01KeyframesFM } from "./Framer Motion/G - Keyframes/G01 - Keyframes"
import { G02ScaleAnimationFM } from "./Framer Motion/G - Keyframes/G02 - Scale animation"
import { G03XandYFM } from "./Framer Motion/G - Keyframes/G03 - X and Y"
import { G04AddingPauseFM } from "./Framer Motion/G - Keyframes/G04 - Adding a pause"
import { G05TimesFM } from "./Framer Motion/G - Keyframes/G05 - Times"
import { G06TimesFastStartFM } from "./Framer Motion/G - Keyframes/G06 - Times - Fast start"
import { G07RecreatedSequenceFM } from "./Framer Motion/G - Keyframes/G07 - Recreated async-await sequence"
import { G08KeyframesUseCycleWhileHoverFM } from "./Framer Motion/G - Keyframes/G08 - useCycle() and WhileHover"
import { G09OneEasingFM } from "./Framer Motion/G - Keyframes/G09 - One easing"
import { G10DifferentEasingsFM } from "./Framer Motion/G - Keyframes/G10 - Different easings"
import { G11LinearVsDifferentEasingsFM } from "./Framer Motion/G - Keyframes/G11 - Linear vs. different easings"
import { H01DraggingWithUseStateFM } from "./Framer Motion/H - Tips for Overrides/H01 - Dragging with useState()"

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function returnComponent(group: string, id: number, count: number) {
    if (group === "A") {
        switch (id) {
            case 2:
                return <A02AnimatingPropertiesFM key={count} />
            case 3:
                return <A03TransformPropertiesFM key={count} />
            case 4:
                return <A04TransformOriginFM key={count} />
            case 5:
                return <A05ColorsFM key={count} />
            case 6:
                return <A06ComplexValuesFM key={count} />
        }
    } else if (group === "B") {
        switch (id) {
            case 1:
                return <B01TheTransitionPropertyFM key={count} />
            case 2:
                return <B02SpringSettingsFM key={count} />
            case 3:
                return <B03LoopAfterDelayFM key={count} />
            case 4:
                return <B04YoyoWithBezierFM key={count} />
            case 5:
                return <B05SeparateTransitionValues1FM key={count} />
            case 6:
                return <B06SeparateTransitionValues2FM key={count} />
        }
    } else if (group === "C") {
        switch (id) {
            case 1:
                return <C01WhileHoverFM key={count} />
            case 2:
                return <C02WhileTapFM key={count} />
            case 3:
                return <C03WhileHoverWhileTapFM key={count} />
            case 4:
                return <C04VariantsFM key={count} />
            case 5:
                return <C05VariantsAndChildrenFM key={count} />
            case 6:
                return <C06OrchestrationFM key={count} />
        }
    } else if (group === "D") {
        switch (id) {
            case 1:
                return <D01TheUseStateHookFM key={count} />
            case 2:
                return <D02MoreStatesFM key={count} />
            case 3:
                return <D03UsingCurrentState1FM key={count} />
            case 4:
                return <D04UsingCurrentState2FM key={count} />
            case 5:
                return <D05MultiplePropertiesFM key={count} />
            case 6:
                return <D06SeparateUseStateForEachPropertyFM key={count} />
            case 7:
                return <D07UsingUseStateOnlyForStateFM key={count} />
        }
    } else if (group === "E") {
        switch (id) {
            case 1:
                return <E01TheUseCycleHookFM key={count} />
            case 2:
                return <E02AnimationFM key={count} />
            case 3:
                return <E03MultiplePropertiesFM key={count} />
            case 4:
                return <E04CyclingToValueFM key={count} />
            case 5:
                return <E05VariantsFM key={count} />
            case 6:
                return <E06ParentChildVariantsFM key={count} />
        }
    } else if (group === "F") {
        switch (id) {
            case 1:
                return <F01StartingAndStoppingFM key={count} />
            case 2:
                return <F02ReturnAfterDragFM key={count} />
            case 3:
                return <F03SequenceFM key={count} />
            case 4:
                return <F04SequenceWithPausesFM key={count} />
            case 5:
                return <F05MultipleFramesFM key={count} />
            case 6:
                return <F06SeparateFramesFM key={count} />
            case 7:
                return <F07DynamicStartFM key={count} />
        }
    } else if (group === "G") {
        switch (id) {
            case 1:
                return <G01KeyframesFM key={count} />
            case 2:
                return <G02ScaleAnimationFM key={count} />
            case 3:
                return <G03XandYFM key={count} />
            case 4:
                return <G04AddingPauseFM key={count} />
            case 5:
                return <G05TimesFM key={count} />
            case 6:
                return <G06TimesFastStartFM key={count} />
            case 7:
                return <G07RecreatedSequenceFM key={count} />
            case 8:
                return <G08KeyframesUseCycleWhileHoverFM key={count} />
            case 9:
                return <G09OneEasingFM key={count} />
            case 10:
                return <G10DifferentEasingsFM key={count} />
            case 11:
                return <G11LinearVsDifferentEasingsFM key={count} />
        }
    } else if (group === "H") {
        switch (id) {
            case 1:
                return <H01DraggingWithUseStateFM key={count} />
        }
    }
}

function QueryParams(props: any) {
    let query = useQuery()

    const group = query.get("id").substring(0, 1)
    const id = Number(query.get("id").substring(1))

    let background
    if (id === 1) {
        background = "#00AAFF"
    } else if (id === 2 || id === 8) {
        background = "#0088FF"
    } else if (id === 3 || id === 9) {
        background = "#0066FF"
    } else if (id === 4 || id === 10) {
        background = "#0044FF"
    } else if (id === 5 || id === 11) {
        background = "#4400FF"
    } else if (id === 6) {
        background = "#6600FF"
    } else if (id === 7) {
        if (group === "D" || group === "F") {
            background = "#8800FF"
        } else if (group === "G") {
            background = "#00AAFF"
        }
    }

    let refresh = null
    if (group === "A") {
        if (id === 2 || id === 3 || id === 4 || id === 5 || id === 6) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "B") {
        if (
            id === 1 ||
            id === 2 ||
            id === 3 ||
            id === 4 ||
            id === 5 ||
            id === 6
        ) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "C") {
        if (id === 4 || id === 5 || id === 6) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "D") {
        if (id === 1 || id === 4 || id === 6 || id === 7) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "E") {
        if (id === 1 || id === 5) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "F") {
        if (id === 1 || id === 2) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "G") {
        if (id === 1 || id === 2) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "H") {
        if (id === 1) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                background: background,
            }}
        >
            {refresh}
            {returnComponent(group, id, props.count)}
        </div>
    )
}

export function App() {
    const [count, setCount] = React.useState(0)

    return (
        <>
            <Router>
                <QueryParams count={count} setCount={setCount} />
            </Router>
        </>
    )
}

export default App
