import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "../center"

export function F03SequenceFM() {
    const animation = useAnimation()

    async function sequence() {
        await animation.start({
            scaleX: 0.2,
            transition: { duration: 1, ease: "backInOut" },
        })
        await animation.start({
            scaleY: 0.2,
            transition: { duration: 1, ease: "backInOut" },
        })
        animation.start({
            scaleX: 1,
            scaleY: 1,
            transition: { ease: "backInOut" },
        })
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animation}
                onTap={sequence}
            />
        </Center>
    )
}
