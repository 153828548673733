import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"

export function D06SeparateUseStateForEachPropertyFM() {
    const [background, setBackground] = useState("#60F")
    const [scale, setScale] = useState(0.8)
    const [cursor, setCursor] = useState("grab")

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#60F",
                overflow: "hidden",
                display: "flex",
                placeItems: "center",
                placeContent: "center",
            }}
            animate={{ backgroundColor: background }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: cursor,
                }}
                // Dragging
                drag
                dragConstraints={{
                    top: -140,
                    right: 140,
                    bottom: 140,
                    left: -140,
                }}
                // Animation
                animate={{
                    scale: scale,
                }}
                onDragStart={() => {
                    setBackground("#09F")
                    setScale(1)
                    setCursor("grabbing")
                }}
                onDragEnd={() => {
                    setBackground("#60F")
                    setScale(0.8)
                    setCursor("grab")
                }}
            />
        </motion.div>
    )
}
