import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D05MultiplePropertiesFM() {
    const [animate, setAnimate] = useState({ scale: 1, rotate: 0 })

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animate}
                onTapStart={() => setAnimate({ scale: 1.25, rotate: 90 })}
                onTap={() => setAnimate({ scale: 1, rotate: 0 })}
            />
        </Center>
    )
}
